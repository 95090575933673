import React from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import styles from "./ImageDocumentViewer.module.css";

type Props = {
  file: string;
}

const ImageDocumentViewer = ({file}: Props) => {
  const [open, setOpen] = React.useState(false);

  return <>
    <Lightbox
      open={open}
      close={() => setOpen(false)}
      carousel={{finite: true}}
      slides={[{ src: `data:image/*;base64,${file}` }]}
      render={{
        buttonPrev: () => null,
        buttonNext: () => null,
      }}
      plugins={[Zoom]}
      animation={{ zoom: 500 }}
      zoom={{
        maxZoomPixelRatio: 3,
        doubleTapDelay: 300,
        doubleClickDelay: 300,
        doubleClickMaxStops: 2,
        keyboardMoveDistance: 50,
        pinchZoomDistanceFactor: 100,
      }}
    />

    <div style={{display: "flex", justifyContent: "center"}}>
      <div className={styles.container} onClick={() => setOpen(true)}>
        <img src={`data:image/*;base64,${file}`} alt="Document" className={styles.image} />
      </div>
    </div>
  </>;
};

export default ImageDocumentViewer;