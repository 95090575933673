import React from 'react';
import WordViewer from "@components/DocumentViewer/office/WordViewer";
import ExcelViewer from "@components/DocumentViewer/office/ExcelViewer";

type Props = {
  file: string;
  type?: string;
}

const OfficeDocumentViewer = ({file, type}: Props) => {
  if(type === 'doc' || type === 'docx') {
    return <WordViewer file={file} />
  }

  if(type === 'xls' || type === 'xlsx') {
    return <ExcelViewer file={file} />;
  }

  return <span>file not compatible for office viewing</span>;
};

export default OfficeDocumentViewer;