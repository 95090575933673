import {ToolbarPlugin} from "@react-pdf-viewer/toolbar";
import {ToolbarSlot} from "@react-pdf-viewer/default-layout";
import {Button} from "primereact/button";

interface ToolbarTemplateProps {
  toolbarPluginInstance: ToolbarPlugin;
}

const ToolbarTemplate = ({ toolbarPluginInstance }: ToolbarTemplateProps) => {
  const { Toolbar } = toolbarPluginInstance;

  const handleButtonToolbar = (onClick: () => void, icon: string, tooltipValue: string) => {
    return (
      <Button
        onClick={onClick}
        icon={icon}
        tooltip={tooltipValue}
        className="p-menuitem-link lg:p-2 p-button-outlined w-full text-left border-none"
        tooltipOptions={{ position: 'bottom', mouseTrackTop: 15 }}
      />
    )
  }

  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          backgroundColor: '#eeeeee',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          display: 'flex',
          padding: '4px',
        }}
      >
        <Toolbar>
          {(slots: ToolbarSlot) => {
            const {
              CurrentPageInput,
              EnterFullScreen,
              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              Zoom,
              ZoomIn,
              ZoomOut,
            } = slots;
            return (
              <div className="align-items-center flex w-full">
                <div className="px-2">
                    <ZoomOut
                      children={({onClick}) => handleButtonToolbar(onClick, "bi-zoom-out", "Afastar")}
                    />
                </div>
                <div className="px-2">
                  <Zoom />
                </div>
                <div className="px-2">
                  <ZoomIn
                    children={({onClick}) => handleButtonToolbar(onClick, "bi-zoom-in", "Aproximar")}
                  />
                </div>
                <div className="px-2 ml-auto">
                  <GoToPreviousPage children={({ onClick }) => handleButtonToolbar(onClick, "bi-caret-up", "Página anterior")} />
                </div>
                <div className="flex px-2 align-items-center">
                  <CurrentPageInput /> / <NumberOfPages />
                </div>
                <div className="px-2">
                  <GoToNextPage children={({ onClick }) =>handleButtonToolbar(onClick, "bi-caret-down", "Próxima página")} />
                </div>
                <div className="px-2 ml-auto md:block hidden">
                  <EnterFullScreen children={({onClick}) => handleButtonToolbar(onClick, "bi-arrows-fullscreen", "Tela cheia")}/>
                </div>
              </div>
            );
          }}
        </Toolbar>
      </div>
    </div>
  );
}

export default ToolbarTemplate;