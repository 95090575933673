export const formatDocumentFileSize = (fileSize: number) => {
  return fileSize < 1000000 ? `${(fileSize / 1000).toFixed(2)}KB` : `${(fileSize / 1000000).toFixed(2)}MB`;
}

export const formatDateStr = (date: string) => {
  return new Date(date).toLocaleString();
}
export const formatDocumentNameRemovingExtension = (name: string) => {
  if(name.indexOf(".") > -1) {
    const formattedName = name.split(".")
    formattedName.pop();
    return formattedName.join(".")
  }
  return name;
}