import {
  DataTable,
  DataTableExpandedRows,
  DataTableRowEvent,
  DataTableSelectionChangeEvent,
  DataTableStateEvent
} from "primereact/datatable";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { SearchedDocumentListDataProps, SearchedDocumentProps } from "@service/DocumentService/types";
import { Column } from "primereact/column";
import { MenuItem } from "primereact/menuitem";
import { DeleteDocumentDialog } from "@components/DeleteDocumentDialog";
import { Tooltip } from "primereact/tooltip";
import { SplitButton } from "primereact/splitbutton";
import { formatDocumentFileSize } from "@others/utils/formatters";
import { ContextMenu } from "primereact/contextmenu";
import { useNavigate } from "react-router-dom";
import { PreviewDocumentPageRoute } from "../PagesRoutes";
import { useDeleteDocument } from "@service/DocumentService/hooks/useDocumentMutation";
import { Toast } from "primereact/toast";

interface SearchedDocumentsTableProps {
  isLoading: boolean;
  refetch: () => void;
  rowsPerPage: number;
  searchedDocumentListDataProps?: SearchedDocumentListDataProps;
  setPage: Dispatch<SetStateAction<number>>;
  setRowPerPage: Dispatch<SetStateAction<number>>;
}

const SearchedDocumentsTable = ({
    setPage,
    setRowPerPage,
    searchedDocumentListDataProps,
    isLoading,
    refetch
  }: SearchedDocumentsTableProps) => {
  const [first, setFirst] = useState(0);
  const [documentsSelectedInTheTable, setDocumentsSelectedInTheTable] = useState<SearchedDocumentProps[]>();
  const [documentsSelectedInTheTableToContext, setDocumentsSelectedInTheTableToContext] = useState<SearchedDocumentProps | null>();
  const [isShowDeleteDocumentDialog, setIsShowDeleteDocumentDialog] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | any>(null);
  const { mutateAsync: mutateDeleteDocument, isLoading: isLoadingDeletion } = useDeleteDocument();
  const rowFloatingMenu = useRef<ContextMenu>(null);
  const toastRef = useRef<Toast>(null);
  const navigate = useNavigate();

  const extensionsFileTypeToIcon = [
    { extensionName: "pdf", icon: "bi-file-earmark-pdf" },
    { extensionName: "png", icon: "bi-file-earmark-image" },
    { extensionName: "jpg", icon: "bi-file-earmark-image" },
    { extensionName: "jpeg", icon: "bi-file-earmark-image" },
  ]

  const onDeleteDocument = async () => {
    if(documentsSelectedInTheTable?.length) {
      for (const doc of documentsSelectedInTheTable){
        await requestDeleteDocument(doc.id)
      }
    }
  }

  const requestDeleteDocument = async (idDoc: number) => {
    await mutateDeleteDocument(idDoc).then(() => {
      mutateOnsuccess();
    }).catch((error) => {
      mutateOnError(error);
    })
  }

  const mutateOnsuccess = () => {
    toastRef.current?.show({
      detail: "Documento(s) excluído(s) com sucesso.",
      life: 5000,
      severity: "success",
      summary: "Sucesso"
    })
    refetch();
    setIsShowDeleteDocumentDialog(false);
  }

  const mutateOnError = (error: any) => {
    setIsShowDeleteDocumentDialog(false);
    toastRef.current?.show({
      detail: error.response.data.message,
      life: 5000,
      severity: "error",
      summary: "Erro"
    })
  }

  const documentNameDataTableTemplate = ({ name, extension }: SearchedDocumentProps) => {
    return (
      <div className="w-14rem lg:text-left text-right">
        <div className="text-overflow-ellipsis md:text-overflow-clip md:w-max white-space-nowrap overflow-hidden">
          <span className="mr-2">{handleExtensionDocumentIcon(extension.name)}</span>
          {name.split(".", 1)[0]}
        </div>
      </div>
    )
  }

  const handleExtensionDocumentIcon = (extension: string) => {
    const extensionIcon = extensionsFileTypeToIcon.find(ext => ext.extensionName === extension);

    return (
      <>
        <Tooltip target=".custom-target-icon" />
        <i
          data-pr-tooltip={extensionIcon ? `.${extensionIcon}` : ''}
          data-pr-position="right"
          className={"custom-target-icon " + (extensionIcon?.icon ?? "bi-file-earmark")}
        >
        </i>
      </>
    )
  }

  const documentDateDataTableTemplate = ({documentDate}: SearchedDocumentProps) => {
    const newDocumentDate = new Date(documentDate)
    newDocumentDate.setDate(newDocumentDate.getDate() + 1);
    return <div className="text-center">{newDocumentDate.toLocaleDateString("pt-BR")}</div>
  }

  const fileSizeDataTableTemplate = ({fileSize}: SearchedDocumentProps) => {
    return <div>{formatDocumentFileSize(fileSize)}</div>
  }

  const rowMenuButtonOptions: MenuItem[] = [
    { label: "Visualizar Documento", icon: "bi-search", command: () => {
      navigate(`${PreviewDocumentPageRoute}/${documentsSelectedInTheTableToContext?.id}`)
    }},
    { label: "Adicionar em um fluxo", icon: "bi-shuffle" },
    { label: "Compartilhar Documento(s)", icon: "bi-share-fill" },
    { label: "Excluir", icon: "bi-trash", command: () => {
      documentsSelectedInTheTableToContext && setDocumentsSelectedInTheTable([documentsSelectedInTheTableToContext])
      setIsShowDeleteDocumentDialog(true);
    }},
    { label: "Visualizar versões", icon: "bi-alarm" },
  ]

  const headerTableTemplate = () => {
    return (
        <div className="grid p-2 justify-content-between align-items-center md:flex-row flex-column">
          <div>
            <span>Lista de documentos</span>
          </div>
        </div>
      )
  }

  const rowExpansionTemplate = (document: SearchedDocumentProps) => {
    return (
      <div className="p-3">
        <DataTable
          value={document.documentType ? document.documentType.fields : []}
          emptyMessage="Nenhum campo cadastrado no Tipo Documental deste documento."
          showGridlines
        >
          <Column field="name" header="Campo"></Column>
          <Column field="name" header="Valor"></Column>
        </DataTable>
      </div>
    );
  };

  const footerTableTemplate = () => {
    return (
      <div className="grid justify-content-between p-3">
        <SplitButton
          model={buttonFooterList}
          label="Ações"
          className="p-button-sm"
          icon="bi-list"
          menuClassName="w-max"
        />
        <label>Tamanho total dos Documentos Selecionados: {calculateTotalOfSelectedDocuments()}</label>
      </div>
    )
  }

  const calculateTotalOfSelectedDocuments = () => {
    return documentsSelectedInTheTable && documentsSelectedInTheTable?.length > 0 &&
      formatDocumentFileSize(documentsSelectedInTheTable.reduce((prev, newV) => prev + newV.fileSize, 0));
  }

  const onRowTableClick = (event: DataTableRowEvent) => {
    rowFloatingMenu.current?.show(event.originalEvent);
    setDocumentsSelectedInTheTableToContext(Object.assign(event.data));
  }

  const buttonFooterList: MenuItem[] = [
    {label: "Visualizar", icon: "bi-search"},
    {label: "Fluxos", icon: "bi-shuffle"},
    {label: "Compartilhar", icon: "bi-share"},
    {label: "Encaminhar Assinatura", icon: "bi-pencil"},
    {label: "Ações em Lote", icon: "bi-list"},
    {label: "Excluir", icon: "bi-trash", command: () => setIsShowDeleteDocumentDialog(true)},
  ]

  const onPageChange = (event: DataTableStateEvent) => {
    setRowPerPage(event.rows)
    setPage(event.page! + 1);
    const startIndex = event.first;
    setFirst(startIndex);
  };

  return (
    <>
      <ContextMenu
        model={rowMenuButtonOptions}
        ref={rowFloatingMenu}
        className="w-max"
        onHide={() => setDocumentsSelectedInTheTableToContext(null)}
      />
      <DataTable
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate={searchedDocumentListDataProps?.data !== undefined ? "Mostrando {first} a {last} de {totalRecords} documentos." : ''}
        loading={isLoading}
        className="p-datatable-sm"
        rowClassName={() => "tooltip-row-table"}
        totalRecords={searchedDocumentListDataProps?.totalCount}
        lazy
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        rows={searchedDocumentListDataProps?.pageSize ?? 15}
        first={first}
        footer={footerTableTemplate}
        onPage={onPageChange}
        rowsPerPageOptions={[15, 25, 50]}
        resizableColumns
        selection={documentsSelectedInTheTable}
        selectionMode="checkbox"
        onSelectionChange={({value}: DataTableSelectionChangeEvent<any[]>) => setDocumentsSelectedInTheTable([...value])}
        paginatorPosition="bottom"
        contextMenuSelection={documentsSelectedInTheTableToContext !== null ? documentsSelectedInTheTableToContext : []}
        dataKey="id"
        onRowClick={(e) => onRowTableClick(e)}
        header={headerTableTemplate}
        value={searchedDocumentListDataProps?.data}
        paginator
        emptyMessage="Nenhum documento encontrado."
        showGridlines
      >
        <Column selectionMode="multiple" className="w-1rem"/>
        <Column expander className="w-1rem" />
        <Column header="Nome" field="name" body={documentNameDataTableTemplate}/>
        <Column header="Tipo Documento" field="documentType.name"/>
        <Column header="Caminho" field="folder.name" className="col-1"/>
        <Column header="Proprietário" field="owner.name"/>
        <Column header="Data do Documento" className="col-1" body={documentDateDataTableTemplate}/>
        <Column header="Tamanho" field="fileSize" className="w-1rem" body={fileSizeDataTableTemplate}/>
        {/*<Column header="Ações" className="w-1rem" body={actionsDataTableTemplate}/>*/}
      </DataTable>

      <DeleteDocumentDialog
        selectedDocuments={documentsSelectedInTheTable}
        isShowDeleteDocumentDialog={isShowDeleteDocumentDialog}
        setIsShowDeleteDocumentDialog={setIsShowDeleteDocumentDialog}
        refetch={refetch}
        isLoadingDeletion={isLoadingDeletion}
        onDeleteDocument={onDeleteDocument}
      />

      <Toast ref={toastRef}/>
    </>
  )
}

export default SearchedDocumentsTable;