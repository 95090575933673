import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from "react";
import { useDocumentBlobToRender, useGetDocumentById } from "@service/DocumentService/hooks/useDocumentQueries";
import { useKeycloak } from "@react-keycloak/web";
import { DocumentProps, DocumentUpdateParams } from "@service/DocumentService/types";
import { useUpdateDocument } from "@service/DocumentService/hooks/useDocumentMutation";
import { UseMutateFunction } from "@tanstack/react-query";
import {DocumentTypesProps} from "@service/DocumentTypeService/types";

interface PreviewDocumentContextData {
  isShowDialogToEditionOpen: boolean;
  setIsShowDialogToEditionOpen: Dispatch<SetStateAction<boolean>>;
  convertedDocumentId: number;
  isKeycloakInitialized: boolean;
  requestedDocumentData: DocumentProps | undefined;
  requestedDocumentForRendering: string | undefined;
  isLoadingDocumentById: boolean;
  refetchDocumentData: () => void;
  isLoadingDocumentToRender: boolean;
  mutateUpdateDocument: UseMutateFunction<void, unknown, DocumentUpdateParams, unknown>;
  isLoadingUpdateDocument: boolean;
  documentTypeFilteredFromList: DocumentTypesProps | undefined;
  setDocumentTypeFilteredFromList: Dispatch<SetStateAction<DocumentTypesProps>>;
}

const PreviewDocumentContext = createContext({} as PreviewDocumentContextData);

export function usePreviewDocumentContext() {
  return useContext(PreviewDocumentContext)
}

interface PreviewDocumentContextProviderProps {
  children: ReactNode;
  documentId: string;
}

export const PreviewDocumentContextProvider = ({ children, documentId }: PreviewDocumentContextProviderProps) => {
  const { initialized: isKeycloakInitialized } = useKeycloak();
  const [isShowDialogToEditionOpen, setIsShowDialogToEditionOpen] = useState(false);
  const [documentTypeFilteredFromList, setDocumentTypeFilteredFromList] = useState<DocumentTypesProps | undefined>();
  const convertedDocumentId = Number(documentId);
  const {
    data: requestedDocumentData,
    isFetching: isLoadingDocumentById,
    refetch: refetchDocumentData
  } = useGetDocumentById(isKeycloakInitialized, convertedDocumentId);
  const { data: requestedDocumentForRendering, isFetching: isLoadingDocumentToRender } = useDocumentBlobToRender(isKeycloakInitialized, convertedDocumentId);

  const { mutate: mutateUpdateDocument, isLoading: isLoadingUpdateDocument } = useUpdateDocument();

  return (
    <PreviewDocumentContext.Provider value={{
      isShowDialogToEditionOpen,
      setIsShowDialogToEditionOpen,
      convertedDocumentId,
      isKeycloakInitialized,
      requestedDocumentData,
      requestedDocumentForRendering,
      isLoadingDocumentById,
      refetchDocumentData,
      isLoadingDocumentToRender,
      mutateUpdateDocument,
      isLoadingUpdateDocument,
      documentTypeFilteredFromList,
      setDocumentTypeFilteredFromList
    }}>
      {children}
    </PreviewDocumentContext.Provider>
  )
}