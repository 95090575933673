import React, {useEffect, useState} from 'react';
import {Card} from "primereact/card";
import PageTitle from "@components/PageTitle";
import {OwnerProps} from "@service/OwnerService/types";
import OwnersDataTable from "@components/OwnersDataTable";
import {useKeycloak} from "@react-keycloak/web";
import {getOwnerByFilter} from "@service/OwnerService";

const OwnersManagementPage = () => {
    const [owners, setOwners] = useState<OwnerProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { keycloak } = useKeycloak();

    useEffect(() => {
        if(keycloak.token) {
            refreshOwners();
        }
    }, [keycloak.token]);

    const refreshOwners = () => {
        setIsLoading(true);
        getOwnerByFilter('').then(data => {
            setOwners(data);
            setIsLoading(false);
        });
    }

    return <Card>
        <PageTitle title="Proprietários" icon="pi pi-users" />

        <OwnersDataTable owners={owners} isLoading={isLoading} />
    </Card>;
};

export default OwnersManagementPage;