import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import DocumentTypeSelection from "../../components/DocumentTypeMultiselectSelection";
import SearchedDocumentsTable from "./SearchedDocumentsTable";
import MoreOptionsToSearch from "./MoreOptionsToSearch";
import { FormEvent, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useSearchDocuments } from "@service/DocumentService/hooks/useDocumentQueries";
import { SearchDocumentProps } from "@service/DocumentService/types";
import OwnerAutocompleteSelection from "../../components/OwnerAutocompleteSelection";
import { useGetDocumentTypeFields } from "@service/DocumentTypeService/hooks/useDocumentTypeMutation";
import { Fieldset } from "primereact/fieldset";
import { useGetAllDocumentTypes } from "@service/DocumentTypeService/hooks/useGetDocumentTypesByName";
import SearchDocumentContextProvider from "../../others/context/SearchDocumentContext";
import SearchDateFilter from "./SearchDateFilter";
import { Divider } from "primereact/divider";
import { OwnerProps } from "@service/OwnerService/types";
import KeywordAutocompleteSelection from "@components/KeywordAutocompleteSelection";
import { InputSwitch } from "primereact/inputswitch";
import FolderTreeSelectionAllFolders from "@components/FolderTreeSelectionAllFolders";
import FieldForPossibleDataType from "./FieldForPossibleDataType";
import {DocumentTypeFieldProps} from "@service/DocumentTypeService/types";

const SearchPage = () => {
  const [isMoreOptionsDialogVisible, setIsMoreOptionsDialogVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const searchPageDefaultValue: SearchDocumentProps = {
    documentName: '',
    documentTypeIds: [],
  };
  const [searchPageData, setSearchPageData] = useState<SearchDocumentProps>(searchPageDefaultValue);
  const [pageSize, setPageSize] = useState(15);
  const [isEnableSearch, setIsEnableSearch] = useState(false);
  const {
    data: searchedDocumentListDataProps,
    refetch,
    isRefetching: isRefetchingDataTable,
    isFetching: isFetchingDataTable
    } = useSearchDocuments({ pageNumber, pageSize, ...searchPageData }, isEnableSearch);
  const [isEnableGetDocumentTypes, setIsEnableGetDocumentTypes] = useState<boolean>(false);

  const { data: documentTypeFields, mutate: mutateDocumentTypeFields } = useGetDocumentTypeFields();
  const { data: documentTypesList } = useGetAllDocumentTypes(isEnableGetDocumentTypes);

  const [ownersSelected, setOwnersSelected] = useState<OwnerProps[]>([]);

  const onSubmitDocumentSearch = (event: FormEvent) => {
    event.preventDefault();
    setIsEnableSearch(true);
    refetch();
  }

  const handleDocumentTypeNameToLegendFieldSet = () => {
    let name;
    if (documentTypesList) {
      if (searchPageData.documentTypeIds) {
        name = documentTypesList.find(docType => {
          if (searchPageData.documentTypeIds) return docType.id === searchPageData.documentTypeIds[0]
          return undefined;
        })?.name
      }
    }
    return name;
  }

  const onSelectedValueInDocumentTypeField = (valueField: string, { id }: DocumentTypeFieldProps) => {
    const fieldsDocType = searchPageData.documentType?.fields !== undefined ?  [...searchPageData.documentType.fields] : []
    const indexFieldDocType = fieldsDocType.findIndex(f => f.id === id);
    if (indexFieldDocType > -1) {
      fieldsDocType[indexFieldDocType].value = valueField;
    } else {
      fieldsDocType.push({ id, value: valueField })
    }

    if(searchPageData.documentTypeIds.length > 0) {
      setSearchPageData((prevState) => ({ ...prevState, documentType: { fields: fieldsDocType, id: searchPageData.documentTypeIds[0] }}));
    }
  }

  const handleLegendFieldSetTemplate = (
      <div className="flex align-items-center text-primary">
        <i className="bi-list mr-2"></i>
        <span className="font-bold">Campos do Tipo Documental -  {handleDocumentTypeNameToLegendFieldSet()}</span>
      </div>
  )

  const onSelectOwner = (value: any) => {
    setSearchPageData((prevState) => {
      return {
        ...prevState,
        ownerIds: value.map((item: OwnerProps) => item.id)
      }
    })
    setOwnersSelected(value)
  }

  const onSelectFolderInTree = (value: any) => {
    setSearchPageData((prevState) => {
      return {
        ...prevState,
        foldersIds: value.map((ids: string[]) => ids)
      }
    })
  }

  const onSelectItemInFilter = (value: any, key: string) => {
    setSearchPageData((prevState) => {
      return {
        ...prevState,
        [key]: value
      }
    })
  }

  useEffect(() => {
    if (searchPageData.documentTypeIds?.length === 1) {
      mutateDocumentTypeFields(searchPageData.documentTypeIds[0]);
    }
  }, [searchPageData.documentTypeIds]);

  return (
    <SearchDocumentContextProvider>
      <Card>
        <form onSubmit={(e) => onSubmitDocumentSearch(e)}>
          <div>
            <h2 className="mb-2">
              <i className="bi-search text-2xl mr-2"></i>
              Busca de Documentos
            </h2>
          </div>
          <hr/>
          <div className="grid mt-3">
            <div className="field mb-0 p-fluid xl:col-3 lg:col-4 col-12">
              <InputText
                value={searchPageData.documentName}
                placeholder="Nome do documento"
                onChange={({ target }) => setSearchPageData((state) => ({...state, documentName: target?.value}))}
              />
            </div>

            <FolderTreeSelectionAllFolders
              onSelectFolderInTree={onSelectFolderInTree}
              selectionMode="checkbox"
              placeholder="Pasta"
              className="col-12 xl:col-3 lg:col-4"
            />

            <DocumentTypeSelection
              documentTypesList={documentTypesList}
              maxSelectedLabels={1}
              onSelectDocumentTypeMultiselect={(val) => onSelectItemInFilter(val, "documentTypeIds")}
              setIsEnableGetDocumentTypes={setIsEnableGetDocumentTypes}
              className="col-12 xl:col-3 lg:col-4"
              placeholder="Tipo Documental"
            />

            <SearchDateFilter
              classNames="col-12 xl:col-3 lg:col-4"
              placeholder="Data Documento"
              setSearchPageData={setSearchPageData}
              searchPageData={searchPageData}
            />

            <OwnerAutocompleteSelection
              multiple
              className="col-12 xl:col-3 lg:col-4"
              onSelectOwner={onSelectOwner}
              formSelectedOwner={ownersSelected}
              placeholder="Proprietário"
            />

            <KeywordAutocompleteSelection
              classNameContainer="col-12 xl:col-3 lg:col-4"
              placeholder="Palavras-chave"
              multiple
              onSelectKeywordAutocomplete={(val) => onSelectItemInFilter(val, "keywords")}
            />

            <div className="flex xl:col-4 lg:col-6 col-12 justify-content-around md:my-0 my-2">
              <div className="flex align-items-center">
                <label className="mr-1" style={{fontSize:"1.06rem"}}>Privado</label>
                <InputSwitch
                  checked={searchPageData.isPrivate ?? false}
                  onChange={(val) => onSelectItemInFilter(val.value, "isPrivate")}
                />
              </div>
              <div className="flex align-items-center">
                <label className="mr-1" style={{fontSize:"1.06rem"}}>Em Checkout</label>
                <InputSwitch
                  checked={searchPageData.isCheckedOut ?? false}
                  onChange={(val) => onSelectItemInFilter(val.value, "isCheckedOut")}
                />
              </div>
            </div>

            <div className="grid mr-0 pr-0 xl:col-2 lg:col-6 col-12 justify-content-end">
                <div className="md:col-3 col-12 md:mr-2">
                  <Button
                    className="p-button-sm w-full"
                    type="submit"
                    loading={isRefetchingDataTable || isFetchingDataTable}
                    tooltipOptions={{position: "top"}}
                    tooltip="Pesquisar"
                    icon="bi-search"
                  />
                </div>
                <div className="md:col-3 col-12">
                  <Button
                    className="p-button-sm w-full"
                    type="button"
                    onClick={() => setSearchPageData(searchPageDefaultValue)}
                    tooltipOptions={{position: "top"}}
                    tooltip="Limpar filtros"
                    icon="bi-funnel"
                  />
                </div>
            </div>

            {(documentTypeFields && documentTypeFields.length > 0 && searchPageData.documentTypeIds && searchPageData.documentTypeIds.length === 1) && (
              <Fieldset legend={handleLegendFieldSetTemplate} className="col-12 p-0 m-0" toggleable>
                <div className="grid">
                  {documentTypeFields.map(field => {
                    return (
                      <div key={field.id} className="md:col-6 col-12 field p-fluid">
                        <label>{field.name}</label>
                        <FieldForPossibleDataType field={field} onSelectedValueInDocumentTypeField={onSelectedValueInDocumentTypeField}/>
                      </div>
                    )
                  })}
                </div>
              </Fieldset>
            )}

            {/*<div className="col-12">*/}
            {/*  <Button*/}
            {/*    icon="bi-funnel-fill"*/}
            {/*    type="button"*/}
            {/*    label="Mais Filtros"*/}
            {/*    className="p-button-sm"*/}
            {/*    onClick={() => setIsMoreOptionsDialogVisible(true)}*/}
            {/*  />*/}
            {/*</div>*/}

            <Divider/>

            <div className="col-12">
              <SearchedDocumentsTable
                isLoading={isRefetchingDataTable || isFetchingDataTable}
                setPage={setPageNumber}
                refetch={refetch}
                rowsPerPage={pageSize}
                setRowPerPage={setPageSize}
                // setIsPreviewDocumentDialogVisible={setIsPreviewDocumentDialogVisible}
                searchedDocumentListDataProps={searchedDocumentListDataProps}
              />
            </div>
          </div>

          <MoreOptionsToSearch
            setSearchPageData={setSearchPageData}
            searchPageData={searchPageData}
            refetchSearchData={refetch}
            isMoreOptionsDialogVisible={isMoreOptionsDialogVisible}
            setIsMoreOptionsDialogVisible={setIsMoreOptionsDialogVisible}
          />
        </form>
      </Card>
    </SearchDocumentContextProvider>
  )
}

export default SearchPage;
