import React from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {Tooltip} from "primereact/tooltip";
import styles from "./UserName.module.css";
import {Chip} from "primereact/chip";

type Props = { name: string };

const UserName = ({name}: Props) => {
  const { keycloak } = useKeycloak();

  if (name === keycloak.tokenParsed?.name) {
    return <Chip label="Você" icon="pi pi-user" style={{ fontSize: "90%" }} />
  }

  return <>
    <Tooltip target=".userName" style={{ fontSize: "80%" }} />
    <span className={`userName ${styles.name}`}
          data-pr-tooltip={name}
          data-pr-position="left">
      {name}
    </span>
  </>;
};

export default UserName;