import React, { useEffect, useRef, useState } from "react";
import { useDownloadDocument } from "@service/DocumentService/hooks/useDocumentMutation";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as zod from "zod"
import { DocumentEditionFormSchema } from "@service/DocumentService/schemas";
import { DocumentProps } from "@service/DocumentService/types";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { ProgressSpinner } from "primereact/progressspinner";
import { OverlayPanel } from "primereact/overlaypanel";
import DocumentViewer from "@components/DocumentViewer";
import NavbarToEditingDocuments from "@components/PreviewDocument/NavbarToEditingDocuments";
import styles from "./PreviewDocument.module.css"
import DocumentEditionDialog from "@components/PreviewDocument/DocumentEditionDialog";
import { usePreviewDocumentContext } from "@others/context/PreviewDocumentContext";
import { Toast } from "primereact/toast";

export type PreviewDocumentFormInput = zod.infer<typeof DocumentEditionFormSchema>

const PreviewDocument = () => {
  const {
    isShowDialogToEditionOpen,
    setIsShowDialogToEditionOpen,
    convertedDocumentId,
    requestedDocumentData,
    requestedDocumentForRendering,
    isLoadingDocumentToRender,
    refetchDocumentData,
    mutateUpdateDocument
  } = usePreviewDocumentContext();

  const [file, setFile] = useState<string>("");
  const [documentData, setDocumentData] = useState<DocumentProps>();

  const { mutateAsync: mutateDownloadDocument, isLoading: isLoadingDownload } = useDownloadDocument();

  const refOverlayWithDocumentData = useRef<OverlayPanel>(null);
  const toastRef = useRef<Toast>(null);

  const documentFormData = useForm<PreviewDocumentFormInput>({
    resolver: zodResolver(DocumentEditionFormSchema)
  });

  const { handleSubmit, reset } = documentFormData;

  const onSubmitDocumentEdition = (document: PreviewDocumentFormInput) => {
    mutateUpdateDocument(document, {
      onSuccess: () => {
        refetchDocumentData();
        toastRef.current?.show({
          severity: "success",
          life: 2000,
          summary: "Sucesso",
          detail: "Documento atualizado."
        });
        setIsShowDialogToEditionOpen(false);
      }
    });
  }

  const onDownloadDocument = () => {
    mutateDownloadDocument(convertedDocumentId).then((blob) => {
      if (requestedDocumentData) {
        createUrlAndDownload(blob, requestedDocumentData);
      }
    })
  }

  const createUrlAndDownload = (blob: Blob, doc: DocumentProps) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${doc.name}.${doc.extension.name}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  useEffect(() => {
    if (requestedDocumentData) {
      setDocumentData(requestedDocumentData);

      if (requestedDocumentForRendering) {
        setFile(requestedDocumentForRendering);
      }
      reset(requestedDocumentData);
    }
  }, [convertedDocumentId, requestedDocumentForRendering, requestedDocumentData])

  return (
      <div className="grid">
        <FormProvider {...documentFormData}>
          <NavbarToEditingDocuments
            onDownloadDocument={onDownloadDocument}
            isLoadingDownload={isLoadingDownload}
            refOverlayWithDocumentData={refOverlayWithDocumentData}
            mutatedDocument={requestedDocumentData}
          />

          {documentData && (
            <div className="bg-gray-500 w-full lg:mr-7 mr-2 ml-2 p-3 py-2 text-white font-bold border-round">
              <span style={{ fontSize: 20 }}>Documento:</span> {documentData?.name}
            </div>
          )}

          <div
            className={styles.viewerContainer}
            style={( isShowDialogToEditionOpen ? { width: "calc(100% - 30rem)" } : {})}
          >
              {
                (isLoadingDocumentToRender) ?
                  <ProgressSpinner className="flex justify-content-center"/>
                  :
                  <div className="col-12 h-full">
                    <DocumentViewer file={file} extension={documentData?.extension.name}/>
                  </div>
              }

            <DocumentEditionDialog
              reset={reset}
              handleSubmit={handleSubmit}
              onSubmitDocumentEdition={onSubmitDocumentEdition}
            />
          </div>
        </FormProvider>

        <Toast ref={toastRef}/>
      </div>
  )
}

export default PreviewDocument;
