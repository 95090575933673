import DocumentTypeDropdownSelection from "@components/DocumentTypeDropdownSelection";
import DocumentTypeFieldsSelection from "@components/DocumentTypeFieldsSelection";
import { useEffect, useState } from "react";
import { DocumentTypeFieldProps, DocumentTypesProps } from "@service/DocumentTypeService/types";
import { usePreviewDocumentContext } from "@others/context/PreviewDocumentContext";
import { useFormContext } from "react-hook-form";
import { PreviewDocumentFormInput } from "@components/PreviewDocument/index";
import { useGetDocumentTypeFields } from "@service/DocumentTypeService/hooks/useDocumentTypeMutation";

interface DocumentTypeAndFieldsSelectionProps {
  onSelectDocumentType: (value: DocumentTypesProps) => void;
  documentTypeDocument: DocumentTypesProps;
}

const DocumentTypeAndFieldsSelection = ({ onSelectDocumentType, documentTypeDocument }: DocumentTypeAndFieldsSelectionProps) => {
  const { getValues, setValue, formState: { dirtyFields }, reset } = useFormContext<PreviewDocumentFormInput>();
  const [documentTypeFields, setDocumentTypeFields] = useState<DocumentTypeFieldProps[]>();
  const { requestedDocumentData } = usePreviewDocumentContext();
  const { mutateAsync: mutateDocumentTypeFields } = useGetDocumentTypeFields();
  const docTypeId = getValues("documentType.id");

  const resetFieldValues = () => {
    getValues("documentType.fields").forEach((value, index) => {
      setValue(`documentType.fields.${index}.value`, "");
    })
  }

  const setInitialFieldValues = (fields: DocumentTypeFieldProps[]): DocumentTypeFieldProps[] => {
    return fields.map((field, i) => {
      if(getValues("documentType.fields")[i]) {
        return {...field, value: getValues("documentType.fields")[i].value}
      }
      return {...field};
    });
  }

  useEffect(() => {
    if(docTypeId) {
      if(dirtyFields.documentType) {
        mutateDocumentTypeFields(docTypeId).then(fields => {
          setDocumentTypeFields(fields);
          setValue("documentType.fields", fields);
          resetFieldValues();
        });
      } else {
        mutateDocumentTypeFields(docTypeId).then(fields => {
          setDocumentTypeFields(setInitialFieldValues(fields));
          setValue("documentType.fields", setInitialFieldValues(fields));
          reset(getValues());
        });
      }
    }
  }, [docTypeId])

  useEffect(() => {
    if(docTypeId && requestedDocumentData) {
      setDocumentTypeFields(requestedDocumentData.documentType.fields);
    }
  }, [requestedDocumentData])

  return (
    <>
      <DocumentTypeDropdownSelection
        className="py-0 col-12"
        onChange={onSelectDocumentType}
        formSelectedDocumentType={documentTypeDocument}
      />

      {documentTypeFields &&
        <DocumentTypeFieldsSelection
          documentTypeFields={documentTypeFields}
        />
      }
    </>
  )
}

export default DocumentTypeAndFieldsSelection;