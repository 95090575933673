import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { FieldErrors } from "react-hook-form/dist/types/errors";

interface FieldFillingErrorWarningProps {
  errors: FieldErrors
}

const FieldFillingErrorWarning = ({ errors }: FieldFillingErrorWarningProps) => {
  const toastRef = useRef<Toast>(null);

  useEffect(() => {
    for(const error in errors) {
      toastRef.current?.show({
        severity: "error",
        summary: "Erro",
        life: 3000,
        detail: errors[error]?.message?.toString()
      })
    }
  }, [errors])

  return (
    <Toast ref={toastRef}/>
  )
}

export default FieldFillingErrorWarning;