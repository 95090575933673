import { useQuery } from "@tanstack/react-query";
import { getAllFolders, getRootFolders } from "../../index";

export const useGetAllRootFolders = (enableRootFoldersRequest: boolean) => useQuery(['rootFolders'], getRootFolders, {
  staleTime: 1000 * 60 * 5,
  keepPreviousData: true,
  enabled: enableRootFoldersRequest
})

export const useGetAllFolders = (enableRequest: boolean) => useQuery(["folders"], getAllFolders, {
  staleTime: 1000 * 60 * 5,
  keepPreviousData: true,
  enabled: enableRequest
})