import {Dispatch, SetStateAction, useEffect, useState} from "react";
import { SelectItem } from "primereact/selectitem";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import { DocumentTypesProps } from "@service/DocumentTypeService/types";
import * as React from "react";

type DocumentTypeSelectionProps = {
  className?: string;
  maxSelectedLabels?: number;
  placeholder?: string;
  display?: "comma" | "chip";
  classNameInput?: string;
  setIsEnableGetDocumentTypes: Dispatch<SetStateAction<boolean>>;
  documentTypesList?: DocumentTypesProps[];
  onSelectDocumentTypeMultiselect: (value: DocumentTypesProps[]) => void;
}

const DocumentTypeSelection = ({
                                 className,
                                 maxSelectedLabels,
                                 placeholder,
                                 display,
                                 classNameInput,
                                 documentTypesList,
                                 setIsEnableGetDocumentTypes,
                                 onSelectDocumentTypeMultiselect
}: DocumentTypeSelectionProps) => {
  const [documentTypeOptions, setDocumentTypeOptions] = useState<SelectItem[]>();
  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentTypesProps[]>();

  const convertDocumentTypeListInOptions = (documentTypes: DocumentTypesProps[]): SelectItem[] => {
    return documentTypes.map(dt => {
      return {
        label: dt.name,
        value: dt.id
      }
    })
  }

  const handleDocumentTypeTemplate = ({ label }:SelectItem) => {
    return <div><i className="bi-file-earmark-fill"></i> {label}</div>
  }

  const onSelectDocumentType = ({ value }:MultiSelectChangeEvent) => {
    setSelectedDocumentType(value);
    onSelectDocumentTypeMultiselect(value);
  }

  useEffect(() => {
    if (documentTypesList) {
      setDocumentTypeOptions(convertDocumentTypeListInOptions(documentTypesList))
    }
  }, [documentTypesList]);

  return (
    <div className={"p-fluid " + className}>
      <MultiSelect
        placeholder={placeholder}
        className={classNameInput}
        maxSelectedLabels={maxSelectedLabels}
        value={selectedDocumentType}
        onChange={onSelectDocumentType}
        multiple
        itemTemplate={handleDocumentTypeTemplate}
        onShow={() => setIsEnableGetDocumentTypes(true)}
        display={display}
        selectedItemsLabel={"{$} Tipos Documentais"}
        filter
        options={documentTypeOptions}
      />
    </div>
  )
}

export default DocumentTypeSelection;