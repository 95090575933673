import { useMutation } from "@tanstack/react-query";
import {
  deleteDocument,
  downloadDocument,
  deletePendingDocuments,
  updateDocument
} from "../../index";

export const useDeleteDocument = () => useMutation(deleteDocument);

export const useDeletePendingDocument = () => useMutation(deletePendingDocuments);

export const useDownloadDocument = () => useMutation(downloadDocument);

export const useUpdateDocument = () => useMutation(updateDocument)
