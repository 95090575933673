import React, {useEffect, useState} from 'react';
import { ExtensionType, getExtensionType } from "@others/utils/documentExtension";
import PDFDocumentViewer from "@components/DocumentViewer/pdf/PDFDocumentViewer";
import ImageDocumentViewer from "@components/DocumentViewer/image/ImageDocumentViewer";
import TextDocumentViewer from "@components/DocumentViewer/text/TextDocumentViewer";
import OfficeDocumentViewer from "@components/DocumentViewer/office/OfficeDocumentViewer";

type Props = {
  file: string;
  extension?: string;
}

const DocumentViewer = ({ file, extension }: Props) => {
  const [extensionType, setExtensionType] = useState<ExtensionType>(ExtensionType.NOT_FOUND);

  useEffect(() => {
    setExtensionType(getExtensionType(extension));
  }, [document, file]);

  if(extensionType === ExtensionType.PDF) {
    return <PDFDocumentViewer file={file} />;
  }

  if(extensionType === ExtensionType.IMAGE) {
    return <ImageDocumentViewer file={file} />;
  }

  if(isTextDocumentViewerCompatible(extensionType)) {
    return <TextDocumentViewer file={file} extensionType={extensionType} />;
  }

  if(extensionType === ExtensionType.MICROSOFT_OFFICE) {
    return <OfficeDocumentViewer file={file} type={extension} />;
  }

  return <></>;
};

const isTextDocumentViewerCompatible = (extensionType: ExtensionType) => {
  return [ExtensionType.TEXT, ExtensionType.HTML, ExtensionType.JSON, ExtensionType.XML].find(e => e === extensionType);
}

export default DocumentViewer;