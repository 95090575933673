import { api } from "../../lib/axios";
import {DocumentTypeFieldProps, DocumentTypesProps} from "./types";

export const getDocumentTypesByName = async (name?: string): Promise<DocumentTypesProps[]> => {
  const response = await api.get('documentTypes',  { headers: { name }})
  return response.data;
}

export const getDocumentTypeFieldsById = async (documentTypeId: number): Promise<DocumentTypeFieldProps[]> => {
  const response = await api.get(`documentTypes/${documentTypeId}/fields`)
  return response.data;
}