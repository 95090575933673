import React from 'react';
import { Column } from "primereact/column";
import { formatDateStr, formatDocumentFileSize } from "@others/utils/formatters";
import { DataTable, DataTableRowEvent } from "primereact/datatable";
import { SimplifiedDocumentI } from "@service/DocumentService/types";
import styles from './SimpleDocumentDataTable.module.css';
import { Button } from "primereact/button";
import DocumentName from "@components/SimpleDocumentDataTable/DocumentName";
import EmptyTable from "@components/SimpleDocumentDataTable/EmptyTable";
import UserName from "@components/SimpleDocumentDataTable/UserName";
import { PreviewDocumentPageRoute } from "../../pages/PagesRoutes";
import { useNavigate } from "react-router-dom";

type Props = {
  documents: SimplifiedDocumentI[];
  isLoading: boolean;
  onDelete?: (document: SimplifiedDocumentI) => void;
}

const DEFAULT_SORT_FIELD = "createdOn";
const DEFAULT_SORT_ORDER = -1; // desc

type DeleteButtonProps = {
  document: SimplifiedDocumentI;
  onClick?: (document: SimplifiedDocumentI) => void;
};

const DeleteButton = ({document, onClick}: DeleteButtonProps) => {
  return <Button icon="pi pi-trash" rounded text
                 tooltip="Remover Documento"
                 tooltipOptions={{ position: "left", style: {fontSize: "80%" }}}
                 aria-label="Remover"
                 onClick={() => onClick && onClick(document)}/>;
}

const SimpleDocumentDataTable: React.FunctionComponent<Props> = ({documents, isLoading, onDelete}) => {
  const navigate = useNavigate();

  const onRowTableClick = (event: DataTableRowEvent) => {
    navigate(`${PreviewDocumentPageRoute}/${event.data.id}`);
  }

  return (
      <DataTable value={documents} stripedRows paginator rows={10} loading={isLoading} rowHover
                 className="p-datatable-sm"
                 rowClassName={() => "cursor-pointer"}
                 emptyMessage={() => <EmptyTable />}
                 sortField={DEFAULT_SORT_FIELD}
                 onRowClick={onRowTableClick}
                 sortOrder={DEFAULT_SORT_ORDER}
                 tableClassName={styles.table}>
        <Column sortable field="name" header="Name" body={(document) => <DocumentName name={document.name} />} />
        <Column className="col-2" sortable field="fileSize" header="Tamanho" body={(document) => formatDocumentFileSize(document.fileSize)} />
        <Column className="col-2" sortable field="lastChangedBy.name" header="Enviado por" body={(document) => <UserName name={document.lastChangedBy?.name} />} />
        <Column className="col-3" sortable field="createdOn" dataType="date" header="Enviado Em" body={(document) => formatDateStr(document.createdOn)} />
        {onDelete && <Column className={`col-1 ${styles.deleteColumn}`} align="right" body={(document) => <DeleteButton document={document} onClick={onDelete} />} />}
      </DataTable>
  )
};

export default SimpleDocumentDataTable;