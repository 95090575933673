import React, {useEffect, useState} from 'react';
import {Card} from "primereact/card";
import {Base64} from "js-base64";
import {read, utils} from "xlsx";

type Props = {
  file: string;
}

const convertXlsxToHtml = (file: string, onSuccess: (result: any) => void) => {
  const byteArray = Base64.toUint8Array(file);

  const wb = read(byteArray);

  const sheetsAsHtml: Array<any> = wb.SheetNames.map((sheetName, index) => {
    const ws = wb.Sheets[sheetName];
    return {
      sheetName: sheetName,
      data: utils.sheet_to_html(ws)
    };
  });

  onSuccess(sheetsAsHtml);
};

const ExcelViewer = ({file}: Props) => {
  const [fileSheets, setFileSheets] = useState<any>();

  useEffect(() => {
    if(!file) return;

    convertXlsxToHtml(file, (convertedFile => setFileSheets(convertedFile)));
  }, [file]);

  return fileSheets?.map((item: any) => (
    <Card subTitle={item.sheetName} key={item.sheetName} style={{marginBottom: 5}}>
      <div dangerouslySetInnerHTML={{__html: item.data}} />
    </Card>
  ));
}

export default ExcelViewer;