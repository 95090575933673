import { api } from "../../lib/axios";
import {
  DocumentHistoryProps,
  DocumentProps,
  DocumentSearchParams, DocumentUpdateParams,
  SearchedDocumentListDataProps,
  SimplifiedDocumentI
} from "./types";
import { Buffer } from "buffer";

export const postDocumentSearch = async (docParams: DocumentSearchParams): Promise<SearchedDocumentListDataProps> => {
  formatSearchRequest(docParams);
  const response = await api.post('documents/search', docParams);
  return response.data;
}

const formatSearchRequest = (docParams: DocumentSearchParams) => {
  if(docParams.documentType && docParams.documentType.fields.length > 0) {
    docParams.documentTypeIds = [];
  }
}

export const getDocumentById = async (documentId: number): Promise<DocumentProps> => {
  const response = await api.get<DocumentProps>(`documents/${documentId}`)
  return response.data;
}

export const deleteDocument = async (docId: number): Promise<void> => {
  await api.delete(`documents/${docId}`);
}

export const uploadDocument = async (file: File): Promise<any> => {
  const response = await api.post('documents/insert', { file: file }, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });

  return response;
}

export const downloadDocument = async (documentId: number): Promise<Blob> => {
  const response = await api.get(`documents/${documentId}/download`, { responseType: "blob" });
  return response.data;
}

export const updateDocument = async (document: DocumentUpdateParams): Promise<void> => {
  await api.put(`documents/${document.id}/update`, document);
}

export const documentBlobToRender = async (documentId: number): Promise<string> => {
  const response = await api.get(`documents/${documentId}/download`, { responseType: "arraybuffer" });
  return Buffer.from(response.data).toString("base64");
}

export const getPendingDocument = async (): Promise<SimplifiedDocumentI[]> => {
  const response = await api.get('documents/pending');
  return response.data;
};

export const deletePendingDocuments = async (docIdList: number[]) => {
  for (const id of docIdList) {
    await api.delete(`documents/pending/${id}`);
  }
};

export const getDocumentHistory = async (docId: number): Promise<DocumentHistoryProps[]> => {
  const response = await api.get<DocumentHistoryProps[]>(`documents/${docId}/history`)
  return response.data;
}