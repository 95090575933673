import { useEffect, useState } from "react";
import { ScrollMode, SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import ToolbarTemplate from "@components/PDFDocumentRender/ToolbarTemplate";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

interface PDFDocumentRenderProps {
  file: string | Uint8Array;
}

const PDFDocumentRender = ({ file }: PDFDocumentRenderProps) => {
  const [fileUrlToRender, setFileUrlToRender] = useState<string | Uint8Array>("");
  const toolbarPluginInstance = toolbarPlugin();

  useEffect(() => {
    setFileUrlToRender(file);
  }, [file]);

  return (
    <>
      {fileUrlToRender !== "" &&
        <div style={{ height: "85vh" }}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <ToolbarTemplate toolbarPluginInstance={toolbarPluginInstance}/>
            <Viewer
              theme="dark"
              scrollMode={ScrollMode.Vertical}
              defaultScale={SpecialZoomLevel.PageFit}
              fileUrl={fileUrlToRender}
              plugins={[toolbarPluginInstance]}
            />
          </Worker>
        </div>
      }
    </>
    )
}

export default PDFDocumentRender;
