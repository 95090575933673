import { FolderProps } from "./types";
import { api } from "../../lib/axios";

export const getAllFolders = async (): Promise<FolderProps[]> => {
  const response = await api.get<FolderProps[]>('/folders');
  return response.data;
}

export const getRootFolders = async (): Promise<FolderProps[]> => {
  const response = await api.get<FolderProps[]>('folders/root')
  return response.data;
}

export const getFolderByQuery = async (name: string): Promise<FolderProps[]> => {
  const response = await api.get<FolderProps[]>('folders', { params: { name } })
  return response.data;
}

export const getFolderById = async (folderId: number): Promise<FolderProps> => {
  const response = await api.get<FolderProps>('folders/' + folderId)
  return response.data;
}

export const getSubFoldersByFolderParentId = async (rootFolderIdList: number[]): Promise<FolderProps[]> => {
  let folders: FolderProps[] = []
  for (const folderId of rootFolderIdList) {
    const response = await api.get<FolderProps[]>(`folders/${folderId}/subfolders`)
    folders.push(...response.data)
  }
  return folders;
}