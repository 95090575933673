import { DocumentTypeFieldProps } from "@service/DocumentTypeService/types";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import { PreviewDocumentFormInput } from "@components/PreviewDocument";
import RequiredFieldIcon from "@components/RequiredFieldIcon";

interface DocumentTypeFieldsSelectionProps {
  classNameField?: string;
  documentTypeFields: DocumentTypeFieldProps[];
}

const DocumentTypeFieldsSelection = ({ classNameField, documentTypeFields }: DocumentTypeFieldsSelectionProps) => {
  const { control } = useFormContext<PreviewDocumentFormInput>();

  const handleFieldToPossibleDataType = (field: DocumentTypeFieldProps, value: string, onChangeValue: (...event: any[]) => void) => {
    const { dataType, possibleValues } = field;

    switch (dataType) {
      case "Booleano":
        return (
          <Checkbox
            checked={value === "true"}
            onChange={(event) => onChangeValue(String(event.checked))}
          />
        )
      case "Inteiro":
        return (
          <InputNumber
            format={false}
            value={Number(value)}
            onChange={(event) => onChangeValue(event.value?.toString())}
          />
        )
      case "Data":
        return (
          <Calendar
            className="p-inputtext-sm"
            value={value ? new Date(value) : ""}
            dateFormat="dd/mm/yy"
            locale="pt-BR"
            onChange={(event) => onChangeValue(event.value?.toString())}
          />
        )
      case "Decimal":
        return (
          <InputNumber
            showButtons
            minFractionDigits={2}
            className="p-inputtext-sm"
            value={Number(value)}
            onChange={(event) => onChangeValue(event.value?.toString())}
          />
        )
      case "Lista":
        return (
          <Dropdown
            options={possibleValues}
            value={value}
            onChange={(event) => onChangeValue(event.value?.toString())}
            className="p-inputtext-sm"
          />
        )
      case "Texto":
        return (
          <InputText
            className="p-inputtext-sm"
            value={value}
            onChange={onChangeValue}
          />
        )
    }
    return <div></div>
  }

  return (
    <>
      {documentTypeFields.map((fieldDocType, index) => {
        return (
          <div key={fieldDocType.id} className={"col-12 field py-0 p-fluid " + classNameField}>
            <Controller
              control={control}
              render={({field: { value, onChange }}) => {
                return (
                  <>
                    {fieldDocType.dataType !== "Booleano" ?
                      <>
                        <label>{fieldDocType.name}</label>
                        {fieldDocType.required && <RequiredFieldIcon/>}
                        {handleFieldToPossibleDataType(fieldDocType, value || "", onChange)}
                      </>
                    :
                      <div>
                        {handleFieldToPossibleDataType(fieldDocType, value || "", onChange)}
                        <label className="ml-1">{fieldDocType.name}</label>
                        {fieldDocType.required && <RequiredFieldIcon/>}
                      </div>
                    }
                  </>
                )
              }}
              name={`documentType.fields.${index}.value`}
            />
          </div>
        )
      })}
    </>
  )
}

export default DocumentTypeFieldsSelection;