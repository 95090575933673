import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API
});

export const setAxiosToken = (token?: string) => {
  api.interceptors.request.use(
    (config) => {
      if(token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )
}