import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import DocumentData from "@components/PreviewDocument/DocumentData";
import DocumentMetadata from "@components/PreviewDocument/DocumentMetadata";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { UseFormHandleSubmit } from "react-hook-form/dist/types/form";
import { PreviewDocumentFormInput } from "@components/PreviewDocument/index";
import { usePreviewDocumentContext } from "@others/context/PreviewDocumentContext";
import { InputSwitch } from "primereact/inputswitch";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";

interface DocumentEditionDialogProps {
  handleSubmit: UseFormHandleSubmit<any>;
  reset: () => void;
  onSubmitDocumentEdition: (document: PreviewDocumentFormInput) => void;
}

const DocumentEditionDialog = (
  {
    handleSubmit,
    reset,
    onSubmitDocumentEdition
  }: DocumentEditionDialogProps) => {
  const {
    requestedDocumentData,
    isShowDialogToEditionOpen,
    setIsShowDialogToEditionOpen,
    isLoadingUpdateDocument
  } = usePreviewDocumentContext();
  const { control, formState: { isDirty }, getValues } = useFormContext<PreviewDocumentFormInput>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isDiscardDialogVisible, setIsDiscardDialogVisible] = useState<boolean>(false);

  const handleFooterDiscardDialog = () => {
    return (
      <div className="grid">
        <div className="md:col-6 col-12">
          <Button
            label="Confirmar"
            className="w-full p-button-sm"
            icon="bi-check"
            onClick={onResetAndCloseDocumentDetailsDialog}
          />
        </div>
        <div className="md:col-6 col-12">
          <Button
            label="Cancelar"
            className="p-button-danger w-full p-button-sm"
            icon="bi-x-lg"
            onClick={() => setIsDiscardDialogVisible(false)}
          />
        </div>
      </div>
    )
  }

  const confirmDiscardChanges = () => {
    if (isDirty) {
      setIsDiscardDialogVisible(true);
      return;
    }

    return onResetAndCloseDocumentDetailsDialog();
  }

  const onResetAndCloseDocumentDetailsDialog = () => {
    setIsDiscardDialogVisible(false);
    reset();
    setIsShowDialogToEditionOpen(false);
  }

  const footerDocumentDetailsDialog = () => {
    return (
      <div>
        <Divider className="mt-0"/>
        <div className="grid">
          <div className="md:col-6 col-12">
            <Button
              form="documentEditionForm"
              label="Salvar"
              loading={isLoadingUpdateDocument}
              disabled={!isEditable}
              className="w-full p-button-sm"
              icon="bi-save"
            />
          </div>
          <div className="md:col-6 col-12">
            <Button
              label="Descartar Alterações"
              disabled={!isEditable}
              className="p-button-danger w-full p-button-sm"
              icon="bi-x-lg"
              onClick={() => setIsDiscardDialogVisible(true)}
            />
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    isDirty || getValues('pending') ? setIsEditable(true) : setIsEditable(false)
  }, [isDirty, getValues('pending')])

  return (
    <Dialog
      onHide={confirmDiscardChanges}
      visible={isShowDialogToEditionOpen}
      position="right"
      className="lg:w-30rem mr-0 h-screen"
      footer={footerDocumentDetailsDialog}
      maximized
      resizable={false}
      draggable={false}
      modal={false}
      contentClassName="pb-0"
      header="Detalhes do documento"
    >
      <form id="documentEditionForm" onSubmit={handleSubmit(onSubmitDocumentEdition)}>
        <div className="col-12 flex align-items-center justify-content-between">
          <span className="bi-person"><b>Usuário</b> <br/>{requestedDocumentData?.lastChangedBy.name}</span>

          <div className="flex align-items-center">
            <label className="mr-2">Somente Leitura</label>
            <Controller
              control={control}
              render={({ field: {value, onChange} }) => {
                return (
                  <InputSwitch checked={value} onChange={onChange}/>
                )
              }}
              name="readOnly"
            />
          </div>
        </div>

        <TabView>
          <TabPanel header="Dados">
            <DocumentData />
          </TabPanel>
          <TabPanel header="Relacionados"></TabPanel>
          <TabPanel header="Metadados">
            <DocumentMetadata/>
          </TabPanel>
        </TabView>
      </form>

      <Dialog
        header="Descartar"
        onHide={() => setIsDiscardDialogVisible(false)}
        visible={isDiscardDialogVisible}
        footer={handleFooterDiscardDialog}
      >
        Confirmar descarte das alterações?
      </Dialog>
    </Dialog>
  )
}

export default DocumentEditionDialog;