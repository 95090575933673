import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dispatch, SetStateAction } from "react";

interface DeleteDocumentDialogProps {
  selectedDocuments?: any[];
  isShowDeleteDocumentDialog: boolean;
  setIsShowDeleteDocumentDialog: Dispatch<SetStateAction<boolean>>;
  refetch?: () => void,
  isLoadingDeletion: boolean;
  onDeleteDocument: () => void;
}

export const DeleteDocumentDialog = (
  {
    selectedDocuments,
    isShowDeleteDocumentDialog,
    setIsShowDeleteDocumentDialog,
    isLoadingDeletion,
    onDeleteDocument
  }: DeleteDocumentDialogProps
) => {
  const handleFooterDeleteDocumentDialog = () => {
    return (
      <div className="grid p-fluid">
        <div className="md:col-6 col-12">
          <Button
            label="Confirmar"
            icon="bi-check-lg"
            className="p-button-sm w-full"
            onClick={() => onDeleteDocument()}
            loading={isLoadingDeletion}
          />
        </div>
        <div className="md:col-6 col-12">
          <Button
            label="Cancelar"
            icon="bi-x-lg"
            className="p-button-danger p-button-sm w-full"
            onClick={() => setIsShowDeleteDocumentDialog(false)}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <Dialog
        onHide={() => setIsShowDeleteDocumentDialog(false)}
        footer={handleFooterDeleteDocumentDialog}
        visible={isShowDeleteDocumentDialog}
        header="Confirmar exclusão do documento"
      >
        <b>Documentos selecionados: </b><br/>
        {selectedDocuments?.map((documents) => {
            return (
              <div key={documents.id}>
                <span>{documents.name}</span><br/>
              </div>
            )
          })
        }
      </Dialog>
    </>
  )
}