import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useGetAllDocumentTypes } from "@service/DocumentTypeService/hooks/useGetDocumentTypesByName";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { DocumentTypesProps } from "@service/DocumentTypeService/types";
import { usePreviewDocumentContext } from "@others/context/PreviewDocumentContext";

interface DocumentTypeDropdownSelectionProps {
  className: string
  onChange: (event: DocumentTypesProps) => void
  formSelectedDocumentType?: DocumentTypesProps
  disabledAndReadOnly?: boolean
}

const DocumentTypeDropdownSelection = ({ className, onChange, formSelectedDocumentType, disabledAndReadOnly }: DocumentTypeDropdownSelectionProps) => {
  const { initialized: isKeycloakInitialized } = useKeycloak();
  const { setDocumentTypeFilteredFromList } = usePreviewDocumentContext();
  const { data: listDocumentTypes } = useGetAllDocumentTypes(isKeycloakInitialized);
  const [documentTypeSelected, setDocumentTypeSelected] = useState<DocumentTypesProps>();

  const onChangeDocumentType = ({ value }: DropdownChangeEvent) => {
    setDocumentTypeSelected(value);
    onChange(value);
  }

  useEffect(() => {
    if(formSelectedDocumentType) {
      const docTypFiltered = listDocumentTypes?.find(dt => formSelectedDocumentType.id === dt.id)
      if(docTypFiltered) {
        setDocumentTypeFilteredFromList(docTypFiltered);
        setDocumentTypeSelected(docTypFiltered);
      }
    }
  }, [formSelectedDocumentType, listDocumentTypes])

  return (
    <div className={"field p-fluid " + className}>
      <label>Tipo Documental</label>
      <Dropdown
        options={listDocumentTypes}
        optionLabel="name"
        disabled={disabledAndReadOnly}
        readOnly={disabledAndReadOnly}
        filter
        placeholder="Selecione"
        value={documentTypeSelected}
        onChange={onChangeDocumentType}
        emptyMessage="Nenhum Tipo Documental encontrado."
      />
    </div>
  )
}

export default DocumentTypeDropdownSelection;