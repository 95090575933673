import { Route, Routes } from "react-router-dom";
import SearchPage from "./pages/SearchPage";
import { DefaultLayout } from "./layouts/DefaultLayout";
import {
  DocumentHistoryPageRoute, OwnersManagementPageRoute,
  PreviewDocumentPageRoute,
  SearchPageRoute,
  UploadDocumentPageRoute
} from "./pages/PagesRoutes";
import UploadDocumentPage from "./pages/UploadDocumentPage";
import PreviewDocumentPage from "./pages/PreviewDocumentPage";
import DocumentHistoryPage from "./pages/DocumentHistoryPage";
import OwnersManagementPage from "./pages/Management/OwnersManagementPage";

export function Router() {
  return (
    <Routes>
      <Route path={'/'} element={<DefaultLayout/>}>
        <Route path={'/'} element={<SearchPage />}/>
        <Route path={SearchPageRoute} element={<SearchPage />}/>
        <Route path={UploadDocumentPageRoute} element={<UploadDocumentPage />}/>
        <Route path={`${PreviewDocumentPageRoute}/:documentId`} element={<PreviewDocumentPage />}/>
        <Route path={`${DocumentHistoryPageRoute}/:documentId`} element={<DocumentHistoryPage />}/>
        <Route path={OwnersManagementPageRoute} element={<OwnersManagementPage />}/>
      </Route>
    </Routes>
  )
}