import {api} from "../../lib/axios";
import {OwnerProps, OwnerTypeI} from "./types"

export const getOwnerByFilter = async (key: string): Promise<OwnerProps[]> => {
  const response = await api.get<OwnerProps[]>("owners", {params: { name: key }});
  return response.data;
}

export const getOwnerTypes = async (): Promise<OwnerTypeI[]> => {
  const response = await api.get<OwnerTypeI[]>("owners/types");
  return response.data;
}