import PageTitle from "@components/PageTitle";
import React from "react";
import { Card  } from "primereact/card";
import { useParams } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DocumentHistoryProps } from "@service/DocumentService/types";
import { formatDateStr } from "@others/utils/formatters";
import { useGetDocumentHistory } from "@service/DocumentService/hooks/useDocumentQueries";

const DocumentHistoryPage = () => {
  const { documentId } = useParams();
  const { initialized: isKeycloakInitialized } = useKeycloak();
  const { data: documentHistoryData, isLoading } = useGetDocumentHistory(Number(documentId), isKeycloakInitialized);

  const handleTimestampDocumentHistoryTemplate = ({ timestamp }: DocumentHistoryProps) => {
    return <>{formatDateStr(String(timestamp))}</>
  }

  const handleMessageDocumentHistoryTemplate = ({ messages }: DocumentHistoryProps) => {
    return (
      <div className="md:ml-0 ml-8">
          {messages}
      </div>
    )
  }

  return (
    <>
      <Card>
        <PageTitle title="Histórico do Documento" icon="bi-card-list" />
        <DataTable
          value={documentHistoryData}
          emptyMessage="Nenhum registro encontrado."
          showGridlines
          rows={10}
          className="text-center"
          paginatorPosition="both"
          size="small"
          header="Histórico"
          rowsPerPageOptions={[10, 25, 50]}
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} eventos."
          loading={isLoading}
          paginator
          pageLinkSize={3}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column header="Data Auditoria" field="timestamp" body={handleTimestampDocumentHistoryTemplate}/>
          <Column header="Usuário" field="user.name"/>
          <Column header="Evento" field="event"/>
          <Column header="Mensagens" field="messages" className="col-5 " body={handleMessageDocumentHistoryTemplate}/>
        </DataTable>
      </Card>
    </>
  )
}

export default DocumentHistoryPage;