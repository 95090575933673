export enum ExtensionType {
  PDF,
  IMAGE,
  TEXT,
  XML,
  JSON,
  HTML,
  MICROSOFT_OFFICE,
  NOT_FOUND
};

export const getExtensionType = (extensionName?: string): ExtensionType => {
  if(extensionName === "pdf") {
    return ExtensionType.PDF;
  }

  if(isJpg(extensionName) || isGif(extensionName) || isPng(extensionName) || isMicrosoftImage(extensionName) || isSvg(extensionName)) {
    return ExtensionType.IMAGE;
  }

  if(isText(extensionName)) return ExtensionType.TEXT;
  if(isXML(extensionName))  return ExtensionType.XML;
  if(isHTML(extensionName)) return ExtensionType.HTML;
  if(isJSON(extensionName)) return ExtensionType.JSON;
  if(isMicrosoftOffice(extensionName)) return ExtensionType.MICROSOFT_OFFICE;

  return ExtensionType.NOT_FOUND;
};

const isJpg = (extensionName?: string): boolean => {
  if(extensionName === "jpg") return true;
  if(extensionName === "jpeg") return true;
  if(extensionName === "jfif") return true;
  if(extensionName === "pjpeg") return true;
  if(extensionName === "pjp") return true;
  return false;
};

const isGif = (extensionName?: string): boolean => {
  if(extensionName === "gif") return true;
  return false;
};

const isPng = (extensionName?: string): boolean => {
  if(extensionName === "png") return true;
  return false;
};

const isSvg = (extensionName?: string): boolean => {
  if(extensionName === "svg") return true;
  return false;
};

const isMicrosoftImage = (extensionName?: string): boolean => {
  if(extensionName === "ico") return true;
  if(extensionName === "cur") return true;
  return false;
};

const isText = (extensionName?: string): boolean => {
  if(extensionName === "txt") return true;
  if(extensionName === "yaml") return true;
  if(extensionName === "yml") return true;
  return false;
};

const isXML = (extensionName?: string): boolean => {
  if(extensionName === "xml") return true;
  return false;
}

const isHTML = (extensionName?: string): boolean => {
  if(extensionName === "html") return true;
  return false;
}

const isJSON = (extensionName?: string): boolean => {
  if(extensionName === "json") return true;
  return false;
}

const isMicrosoftOffice = (extensionName?: string): boolean => {
  if(extensionName === "doc") return true;
  if(extensionName === "docx") return true;
  if(extensionName === "xls") return true;
  if(extensionName === "xlsx") return true;
  return false;
}