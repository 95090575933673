import React, {useRef} from 'react';
import {FileUpload as PRFileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import {Toast} from "primereact/toast";
import {uploadDocument} from "../../service/DocumentService";
import styles from "./FileUpload.module.css";
import {useAppContext} from "../../others/context/AppContext";

type Props = {
  onUploadDocument: () => void;
};

const FileUpload = ({onUploadDocument}: Props) => {

  const refFileUpload = useRef(null);
  const toast = useRef(null);
  const {currentValue: { license }} = useAppContext();

  const VALID_DOCUMENTS_EXTENSIONS = ['image/*', '.pdf'];

  const uploadHandler = (event: FileUploadHandlerEvent) => {
    event?.files?.forEach((file) => {
      uploadDocument(file).then(response => {
        if(response.status === 200) {
          // @ts-ignore
          toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Documento enviado'});
        } else {
          // @ts-ignore
          toast.current.show({severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro ao enviar o documento, por favor tente novamente'});
        }

        // @ts-ignore
        refFileUpload.current?.clear();
        onUploadDocument();
      });
    });
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-file p-4" style={{ fontSize: '4em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }} className="my-3">
                    Arraste e solte arquivos aqui para upload.
                </span>
      </div>
    );
  };

  const loadingTemplate = (file: File) => {
    return (
      <div className={styles.fileNameBoxTemplate}>
        <span className={styles.fileNameTemplate}>{file.name}</span>
        <i className="pi pi-spin pi-spinner p-3" style={{ fontSize: '1em', borderRadius: '50%', backgroundColor: 'var(--surface-b)'}}></i>
      </div>
    );
  }

  return <div>
    <Toast ref={toast}></Toast>

    <PRFileUpload name="documents[]"
                       customUpload
                       auto
                       multiple
                       chooseLabel="Selecionar"
                       cancelLabel="Cancelar"
                       ref={refFileUpload}
                       accept={VALID_DOCUMENTS_EXTENSIONS.join(',')}
                       maxFileSize={license?.maxFileSize}
                       itemTemplate={loadingTemplate}
                       emptyTemplate={emptyTemplate}
                       uploadHandler={uploadHandler}
    />
  </div>;
};

export default FileUpload;