import { useQuery } from "@tanstack/react-query";
import {
  documentBlobToRender,
  downloadDocument,
  getDocumentById,
  getDocumentHistory,
  postDocumentSearch
} from "../../index";
import { DocumentSearchParams } from "../../types";

export const useSearchDocuments = (
  searchData: DocumentSearchParams,
  isEnableSearch: boolean
) => useQuery(["searchedDocuments", [searchData.pageNumber, searchData.pageSize]], {
  queryFn: () => postDocumentSearch({ ...searchData }),
  keepPreviousData: true,
  enabled: isEnableSearch,
  staleTime: 1000 * 60 * 5
})

export const useGetDocumentById = (
  isEnabledRequest: boolean,
  documentId: number
) => useQuery([`documentSearched${documentId}`, [documentId]], {
  queryFn: () => getDocumentById(documentId),
  enabled: isEnabledRequest,
  keepPreviousData: true,
  staleTime: 1000 * 60 * 5
})

export const useDocumentBlobToRender = (
  isEnabledRequest: boolean,
  documentId: number
) => useQuery([`documentBlobToRender${documentId}`, [documentId]], {
  queryFn: () => documentBlobToRender(documentId),
  enabled: isEnabledRequest,
  keepPreviousData: true,
  staleTime: 1000 * 60 * 5
})

export const useDownloadDocument = (
  documentId: number
) => useQuery([`downloadedDocument${documentId}`, [documentId]], {
  queryFn: () => downloadDocument(documentId),
  keepPreviousData: true,
  staleTime: 1000 * 60 * 5
})

export const useGetDocumentHistory = (
  documentId: number,
  isEnableRequest: boolean
) => useQuery([`documentHistories${documentId}`, [documentId]], {
  queryFn: () => getDocumentHistory(documentId),
  keepPreviousData: true,
  enabled: isEnableRequest,
  staleTime: 1000 * 60 * 5
})