import React, {useEffect, useState} from 'react';
import {Card} from "primereact/card";
import * as mammoth from "mammoth";
import {Base64} from "js-base64";

type Props = {
  file: string;
}

const convertDocxToHtml = (file: string, onSuccess: (result: any) => void) => {
  mammoth.convertToHtml({arrayBuffer: Base64.toUint8Array(file)}).then(result => {
    onSuccess(result.value);
  });
};

const WordViewer = ({file}: Props) => {
  const [fileContent, setFileContent] = useState<any>();

  useEffect(() => {
    if(!file) return;

    convertDocxToHtml(file, (convertedFile) => setFileContent(convertedFile));
  }, [file]);

  return <Card>
    <div dangerouslySetInnerHTML={{__html: fileContent}} />
  </Card>
}

export default WordViewer;