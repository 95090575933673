import React from 'react';
import styles from './PageTitle.module.css';
import {Divider} from "primereact/divider";

type Props = {
  title: string;
  icon?: string;
  className?: string;
  style?: object;
}

const PageTitle = ({ title, icon, className, style }: Props) => {
  return <div className={`${styles.card} ${className}`} style={style}>
    <h2 className="mb-2">
      <i className={`${icon} text-2xl mr-2`}></i>
      {title}
    </h2>
    <Divider />
  </div>;
};

export default PageTitle;