import PreviewDocument from "@components/PreviewDocument";
import { useParams } from "react-router-dom";
import { PreviewDocumentContextProvider } from "@others/context/PreviewDocumentContext";

const PreviewDocumentPage = () => {
  const { documentId } = useParams();

  return (
    <PreviewDocumentContextProvider documentId={documentId || ""}>
      <PreviewDocument/>
    </PreviewDocumentContextProvider>
  )
}

export default PreviewDocumentPage;