import { useEffect, useState } from "react";
import {AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent} from "primereact/autocomplete";
import { useGetOwnerByKey } from "@service/OwnerService/hooks/useOwnerMutation";
import { OwnerProps } from "@service/OwnerService/types";
import styles from "./OwnerAutocompleteSelection.module.css";

interface OwnerInputSelectionProps {
  className?: string;
  label?: string;
  placeholder?: string;
  onSelectOwner: (item: OwnerProps) => void;
  multiple: boolean;
  formSelectedOwner?: OwnerProps | OwnerProps[];
  disabledAndReadOnly?: boolean
}

const OwnerAutocompleteSelection = ({
                               className,
                               label,
                               placeholder,
                               onSelectOwner,
                               multiple,
                               formSelectedOwner,
                               disabledAndReadOnly
}: OwnerInputSelectionProps) => {
  const { mutate: mutateOwner } = useGetOwnerByKey();
  const [searchedOwners, setSearchedOwners] = useState<OwnerProps[]>([]);
  const [ownerSelected, setOwnerSelected] = useState<OwnerProps | OwnerProps[]>();

  const onSearchOwnerByName = ({ query }: AutoCompleteCompleteEvent) => {
    mutateOwner(query, {
      onSuccess: (data) => {
        setSearchedOwners(data);
      }
    });
  }

  const handleSuggestionTemplate = ({ name, cpf, cnpj }: OwnerProps) => {
    let data = cpf ? cpf : cnpj ? cnpj : '';

    return <span><i className="bi-person-fill"></i> { name + (data && ` | ${data}`) }</span>
  }

  const handleSelectedItem = ({ name }: OwnerProps) => {
    if (multiple) {
      return <div><i className="bi-person-fill"></i> {name}</div>
    }
    return name;
  }

  const onChangeOwner = ({ value }: AutoCompleteChangeEvent) => {
    onSelectOwner(value);
    setOwnerSelected(value);
  }

  useEffect(() => {
    if(formSelectedOwner) {
      setOwnerSelected(formSelectedOwner);
    }
  }, [formSelectedOwner])

  return (
    <div className={"p-fluid " + className}>
      <label>{label}</label>
      <AutoComplete
        minLength={2}
        disabled={disabledAndReadOnly}
        readOnly={disabledAndReadOnly}
        className={styles.ownerAutocompleteInput}
        placeholder={placeholder}
        multiple={multiple}
        value={ownerSelected}
        suggestions={searchedOwners}
        field="name"
        selectedItemTemplate={handleSelectedItem}
        itemTemplate={handleSuggestionTemplate}
        onChange={onChangeOwner}
        completeMethod={onSearchOwnerByName}
      />
    </div>
  )
}

export default OwnerAutocompleteSelection;