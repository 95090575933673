import { createContext, ReactNode } from "react";

interface SearchDocumentContextData {}

const SearchDocumentContext = createContext({} as SearchDocumentContextData);

interface SearchDocumentContextProviderProps {
  children: ReactNode;
}

const SearchDocumentContextProvider = ({ children }: SearchDocumentContextProviderProps) => {


  return (
    <SearchDocumentContext.Provider value={{children}}>
      {children}
    </SearchDocumentContext.Provider>
  )
}

export default SearchDocumentContextProvider;