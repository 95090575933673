import * as zod from "zod"

const DocumentTypeSchema = zod.object({
  fields: zod.array(zod.object({
    id: zod.number(),
    name: zod.string().nullish(),
    required: zod.boolean().nullish(),
    value: zod.string().optional(),
  })),
  id: zod.number(),
  name: zod.string().nullish(),
  validationRequired: zod.boolean().nullish()
}, { required_error: "Tipo Documental obrigatório." })

export const DocumentEditionFormSchema = zod.object({
  checkout: zod.boolean().default(false),
  documentDate: zod.string(),
  documentType: DocumentTypeSchema.superRefine(({ fields}, ctx) => {
      for(const val in fields) {
        if (fields[val].required && !fields[val].value) {
          ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: `Campo "${fields[val].name}" obrigatório.`
          });
        }
      }
  }),
  fileName: zod.string(),
  folderId: zod.number({ required_error: "Pasta obrigatória." }),
  folder: zod.object({
    folderParentId: zod.number().nullish(),
    id: zod.number(),
    name: zod.string()
  }).nullish(),
  id: zod.number(),
  keywords: zod.array(zod.string()).optional(),
  makePrivate: zod.boolean().default(false),
  name: zod.string().min(1, "Nome do Documento obrigatório."),
  ocrEnabled: zod.boolean().default(false),
  owner: zod.object({
    id: zod.number(),
    name: zod.string(),
    cnpj: zod.string().optional(),
    cpf: zod.string().optional(),
  }),
  ownerId: zod.number({ required_error: "Proprietário obrigatório." }),
  pending: zod.boolean().nullish(),
  readOnly: zod.boolean().default(false),
  signed: zod.boolean(),
  validUntil: zod.date().nullish()
}).superRefine((val, ctx) => {
  if(val.documentType.validationRequired && !val.validUntil) {
    ctx.addIssue({
      code: zod.ZodIssueCode.custom,
      message: `Obrigatório informar a validade do documento.`
    });
  }
})
