import { useEffect, useState } from "react";
import TreeNode from "primereact/treenode";
import { FolderProps } from "@service/FolderService/types";
import { useGetAllFolders } from "@service/FolderService/hooks/useFolderQueries";
import { TreeSelect, TreeSelectChangeEvent, TreeSelectSelectionKeysType } from "primereact/treeselect";

interface FolderTreeSelectionProps {
  selectionMode: 'single' | 'checkbox';
  onSelectFolderInTree: (value: any) => void;
  label?: string;
  className?: string;
  placeholder?: string;
  formSelectedFolder?: FolderProps;
}

const FolderTreeSelection = ({
                               selectionMode,
                               onSelectFolderInTree,
                               label,
                               className,
                               placeholder,
                               formSelectedFolder
}: FolderTreeSelectionProps) => {
  const [treeFolderList, setTreeFolderList] = useState<TreeNode[]>([]);
  const [selectedFoldersInTree, setSelectedFoldersInTree] = useState<(string | TreeSelectSelectionKeysType | TreeSelectSelectionKeysType[] | null | undefined)>();

  const { isLoading: isLoadingRootFolders, data: folderList } = useGetAllFolders(true);

  const convertFoldersToTreeFolder = (folderListToConvert: FolderProps[]): TreeNode[] => {
    return folderListToConvert.map((folder: FolderProps) => {
      const { id, name } = folder;
      return {
        label: name,
        id: id.toString(),
        icon: "pi pi-folder",
        key: formatSelectedFolderKey(folder)
      }
    })
  }

  const putChildFoldersInsideParent = (treeList: TreeNode[]) => {
    treeList.forEach((folder) => {
      folder.children = treeList.filter((item) => {
        if (item.key && folder.id) {
          if(folder.id !== item.id && item.key.toString().indexOf(folder.id) > -1) {
            return item;
          }
        }
        return null;
      })
    })

    let i = treeList.length
    while (i--) {
      if (treeList[i].key && treeList[i].key!.toString().indexOf("-") > -1) {
        treeList.splice(i, 1);
      }
    }
  }

  const onFolderTreeSelect = (event: TreeSelectChangeEvent) => {
    setSelectedFoldersInTree(event.value);
    if(selectionMode !== "single") {
      onSelectFolderInTree(formatSelectedFoldersToListIds(event));
      return;
    }

    const folderSelected = folderList?.find(item => item.id === formatSelectedFolderInIdNumber(event.value))
    onSelectFolderInTree(folderSelected);
  }

  const formatSelectedFoldersToListIds = ({ value }: TreeSelectChangeEvent) => {
    let ids;
    if(value) {
       ids = Object.keys(value).map(id => {
         if(id.indexOf("-") > -1) {
           return id.split("-")[0];
         }
         return id;
       })
    }
    return ids;
  }

  const formatSelectedFolderInIdNumber = (id: any): number => {
      if(typeof id === "string") {
        return parseInt(id.split("-")[0]);
      }
      return id;
  }

  const formatSelectedFolderKey = (selectedFolder: FolderProps) => {
    const { folderParentId, id } = selectedFolder;
    const folder = folderParentId ? `${id}-${folderParentId}` : id;
    return folder.toString();
  }

  useEffect(() => {
    if(formSelectedFolder) {
      setSelectedFoldersInTree(formatSelectedFolderKey(formSelectedFolder));
    }
  }, [formSelectedFolder, treeFolderList])

  useEffect(() => {
    if(folderList) {
      const folders = convertFoldersToTreeFolder(folderList);
      putChildFoldersInsideParent(folders);
      setTreeFolderList(folders);
    }
  }, [folderList])

  return (
    <div className={"p-fluid " + className}>
      <label>{label}</label>
      <TreeSelect
        selectionMode={selectionMode}
        options={treeFolderList}
        value={selectedFoldersInTree}
        onChange={onFolderTreeSelect}
        metaKeySelection
        placeholder={placeholder}
        display="comma"
        emptyMessage={isLoadingRootFolders ? "Carregando..." : "Nenhuma pasta encontrada."}
        panelClassName="absolute overflow-auto "
        className="w-full"
        filter
      />
    </div>
  )
}

export default FolderTreeSelection;