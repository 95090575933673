import React, {useEffect, useRef, useState} from 'react';
import styles from './UploadDocumentPage.module.css';
import { Card } from "primereact/card";
import PageTitle from "@components/PageTitle";
import FileUpload from '@components/FileUpload';
import { SimplifiedDocumentI } from "@service/DocumentService/types";
import { getPendingDocument } from "@service/DocumentService";
import { useKeycloak } from "@react-keycloak/web";
import { Button } from "primereact/button";
import SimpleDocumentDataTable from "@components/SimpleDocumentDataTable";
import { useDeletePendingDocument } from "@service/DocumentService/hooks/useDocumentMutation";
import { Toast } from "primereact/toast";

const UploadDocumentPage = () => {

  const [filteredPendingDocuments, setFilteredPendingDocuments] = useState<SimplifiedDocumentI[]>([]);
  const [pendingDocuments, setPendingDocuments] = useState<SimplifiedDocumentI[]>([]);
  const { mutate: mutateDeleteDocument, isLoading: isLoadingDelete } = useDeletePendingDocument();
  const [isLoading, setIsLoading] = useState(false);
  const [myDocumentsFilter, setMyDocumentsFilter] = useState(false);
  const { keycloak } = useKeycloak();
  const toastRef = useRef<Toast>(null);

  useEffect(() => {
    if(keycloak.token) {
      refreshPendingDocuments();
    }
  }, [keycloak.token]);

  useEffect(() => {
    setIsLoading(isLoadingDelete);
  }, [isLoadingDelete]);

  useEffect(() => {
    filterDocumentsByLoggedUserDocuments();
  }, [myDocumentsFilter]);

  const filterDocumentsByLoggedUserDocuments = () => {
    const loggedUserId = keycloak?.tokenParsed && +keycloak?.tokenParsed.userId;

    if(myDocumentsFilter) {
      setFilteredPendingDocuments(pendingDocuments.filter(d => d.lastChangedBy?.id === loggedUserId));
    } else {
      setFilteredPendingDocuments(pendingDocuments);
    }
  }

  useEffect(() => {
    filterDocumentsByLoggedUserDocuments();
  }, [pendingDocuments]);

  const refreshPendingDocuments = () => {
    setIsLoading(true);
    getPendingDocument().then(data => {
      setPendingDocuments(data);
      setIsLoading(false);
    });
  }

  const onDeleteDocument = (document: SimplifiedDocumentI) => {
    mutateDeleteDocument([document.id], {
      onSuccess: () => {
        toastRef.current?.show({
          detail: "Documento(s) excluído(s) com sucesso.",
          life: 5000,
          severity: "success",
          summary: "Sucesso"
        })
        refreshPendingDocuments();
      },
      onError: (error: any) => {
        toastRef.current?.show({
          detail: error.response?.data?.message || "Por favor tente novamente ou entre em contato com nosso suporte para lhe ajudarmos.",
          life: 5000,
          severity: "error",
          summary: "Ops, ocorreu um erro"
        })
      }
    })
  };

  return <div className="flex flex-row justify-content-evenly flex-wrap gap-3">
    <Toast ref={toastRef}/>
    <Card className={`sm:min-w-full md:min-w-full lg:min-w-min ${styles.uploadDocumentsCard}`}>
      <PageTitle title="Upload de Documentos" icon="bi-upload" />

      <div>
        <FileUpload onUploadDocument={() => refreshPendingDocuments()} />
      </div>
    </Card>

    <Card className="flex-1">
      <div className="flex justify-content-between">
        <PageTitle className={styles.pageTitle} title="Documentos Pendentes" icon="pi pi-file" />

        <div className="flex align-items-baseline">
          <Button label="Meus Documentos" rounded
                  icon="pi pi-user"
                  style={{ fontSize: "80%", padding: 10, marginRight: 10 }}
                  outlined={!myDocumentsFilter}
                  onClick={() => setMyDocumentsFilter(!myDocumentsFilter)}/>

          <Button icon="pi pi-refresh" rounded text raised
                  style={{ fontSize: "80%", padding: 10 }}
                  aria-label="Atualizar"
                  onClick={() => refreshPendingDocuments()}
          />
        </div>
      </div>

      <SimpleDocumentDataTable documents={filteredPendingDocuments}
                               isLoading={isLoading}
                               onDelete={onDeleteDocument} />
    </Card>
  </div>;
};

export default UploadDocumentPage;