import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DocumentTypeFieldProps } from "@service/DocumentTypeService/types";
import { useState } from "react";

interface FieldForPossibleDataTypeProps {
    field: DocumentTypeFieldProps;
    onSelectedValueInDocumentTypeField: (value: string, field: DocumentTypeFieldProps) => void;
}
const FieldForPossibleDataType = ( {
                                       field,
                                       onSelectedValueInDocumentTypeField }: FieldForPossibleDataTypeProps
) => {
    const [value, setValue] = useState<string | undefined>(undefined);

    const onSelectedValue = (valueField: string) => {
        setValue(valueField);
        onSelectedValueInDocumentTypeField(valueField, field);
    }

    switch (field.dataType) {
        case "Booleano":
            return (
                <Checkbox
                    checked={value === "true"}
                    onChange={(event) => onSelectedValue(String(event.checked))}
                />
            );
        case "Inteiro":
            return (
                <InputNumber
                    format={false}
                    value={value ? Number(value) : 0}
                    showButtons
                    onChange={(data) => onSelectedValue(data.value?.toString() || "")}
                    className="p-inputtext-sm"
                />
            );
        case "Data":
            return (
                <Calendar
                    className="p-inputtext-sm"
                    value={value ? new Date(value) : ""}
                    dateFormat="dd/mm/yy"
                    locale="pt-BR"
                    onChange={(event) => onSelectedValue(event.value?.toString() || "")}
                />
            );
        case "Decimal":
            return (
                <InputNumber
                    showButtons
                    value={value ? Number(value) : 0}
                    minFractionDigits={2}
                    className="p-inputtext-sm"
                    onChange={(data) => onSelectedValue(data.value?.toString() || "")}
                />
            );
        case "Lista":
            return (
                <Dropdown
                    options={field.possibleValues}
                    value={value}
                    className="p-inputtext-sm"
                    onChange={(data) => onSelectedValue(data.value)}
                />
            );
        case "Texto":
            return (
                <InputText
                    className="p-inputtext-sm"
                    value={value}
                    onChange={(data) => onSelectedValue(data.target.value)}
                />
            );
    }
    return <div></div>;
}

export default FieldForPossibleDataType;