import React, {useEffect, useState} from 'react';
import {Base64} from "js-base64";
import * as vkbeautify from 'vkbeautify';
import {InputTextarea} from "primereact/inputtextarea";
import {ExtensionType} from "@others/utils/documentExtension";
import styles from './TextDocumentViewer.module.css';

type Props = {
  file: string;
  extensionType: ExtensionType;
};

const TextDocumentViewer = ({file, extensionType}: Props) => {
  const [fileAsText, setFileAsText] = useState<string>();

  useEffect(() => {
    if(!file) return;

    const decodedFile = Base64.decode(file);
    const prettyFile = prettifyFile(decodedFile, extensionType);

    setFileAsText(prettyFile);
  }, [file, extensionType]);

  return <InputTextarea value={fileAsText} readOnly className={styles.textArea} />;
};

const prettifyFile = (file: string, extensionType: ExtensionType) => {
  if(ExtensionType.XML === extensionType || ExtensionType.HTML === extensionType) {
    return vkbeautify.xml(file, 4 );
  }

  if(ExtensionType.JSON === extensionType) {
    return vkbeautify.json(file, 4 );
  }

  return file;
}

export default TextDocumentViewer;