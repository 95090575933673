import React, {useEffect, useState} from 'react';
import PDFDocumentRender from "@components/PDFDocumentRender";
import {Base64} from "js-base64";

type Props = {
  file: string | undefined; // base64 format
}

const PDFDocumentViewer = ({file}: Props) => {
  const [fileAsArray, setFileAsArray] = useState<string | Uint8Array>("");

  useEffect(() => {
    if(!file) return;

    const base = Base64.toUint8Array(file);
    setFileAsArray(base);
  }, []);

  return <PDFDocumentRender file={fileAsArray} />;
};

export default PDFDocumentViewer;