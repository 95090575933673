import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

const DocumentMetadata = () => {
  return (
    <div className="grid overflow-auto">
      <div className="col-12">
        <span className="text-primary">
          Visualizar metadados do arquivo
        </span>
      </div>
      <div className="field p-fluid col-12 align-items-center mb-0 flex">
        <div className="align-items-center flex">Sincronizar metadados com o arquivo PDF<span className="ml-2"><InputSwitch checked={true}/></span> </div>
      </div>

      <div className="field p-fluid col-12">
        <label>Autor</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Assunto</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Responsável pela Digitalização</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Título</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Classe</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Data Digitalização</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Local Digitalização</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Data Produção</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Local Produção</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Destinação Prevista</label>
        <InputText className="p-inputtext-sm"/>
      </div>

      <div className="field p-fluid col-12">
        <label>Prazo de Guarda (em anos)</label>
        <InputText className="p-inputtext-sm"/>
      </div>
    </div>
  )
}

export default DocumentMetadata;