import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import styles from "./MenuBar.module.css";
import {OwnersManagementPageRoute, SearchPageRoute, UploadDocumentPageRoute} from "../../pages/PagesRoutes";
import { MegaMenu } from "primereact/megamenu";

const CustomMenuBar = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const items = [
    {
      label: 'Upload',
      icon: 'pi pi-upload',
      command: () => navigate(UploadDocumentPageRoute)
    },
    {
      label: 'Buscar',
      icon: 'pi pi-search',
      command: () => navigate(SearchPageRoute)
    },
    {
      label: 'Configurações',
      icon: 'pi pi-cog',
      items: [
        [
          {
            label: 'Gerais',
            items: [
              {
                label: 'Pastas',
                icon: 'pi pi-folder',
                command: () => alert('Em construção')
              },
              {
                label: 'Proprietários',
                icon: 'pi pi-users',
                command: () => navigate(OwnersManagementPageRoute)
              }
            ]
          }
        ]
      ]
    },
    {
      label: 'Sair',
      icon: 'pi pi-power-off',
      command: () => keycloak.logout()
    }
  ];

  const start = <div style={{display: 'flex'}}>
    <img alt="logo" src="/favicon.ico" height="25" className="mr-2"></img>
    <h3 className="mr-2">Megaged</h3>
  </div>;

  const end = <div>
    <i className={`pi pi-user ${styles.icon}`} />
    <span>{keycloak?.tokenParsed?.name}</span>
  </div>;

  return <MegaMenu style={{borderRadius: 0}} start={start} model={items} end={end} />
};

export default CustomMenuBar;