import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useState } from "react";
import { KeywordProps } from "@service/KeywordService/types";
import { useGetKeywordByName } from "@service/KeywordService/hooks/useKeywordMutation";
import styles from "./KeywordAutocompleteSelection.module.css";

interface KeywordAutocompleteSelectionProps {
  classNameContainer?: string;
  placeholder?: string;
  label?: string;
  multiple: boolean;
  onSelectKeywordAutocomplete: (value: KeywordProps[]) => void;
}

const KeywordAutocompleteSelection = ({
                                        classNameContainer,
                                        placeholder,
                                        label,
                                        multiple,
                                        onSelectKeywordAutocomplete
}: KeywordAutocompleteSelectionProps) => {
  const [filteredKeywords, setFilteredKeywords] = useState<string[]>();
  const { mutateAsync: onMutateGetKeywordByName } = useGetKeywordByName();
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>();

  const onSearchKeyword = ({ query }: AutoCompleteCompleteEvent) => {
    onMutateGetKeywordByName(query).then((data) => {
      setFilteredKeywords(data);
    })
  }

  const onSelectKeyword = ({ value }: AutoCompleteChangeEvent) => {
    setSelectedKeywords(value);
    onSelectKeywordAutocomplete(value);
  }

  return  (
    <div className={"p-fluid " + classNameContainer}>
      <label>{label}</label>
      <AutoComplete
        suggestions={filteredKeywords}
        placeholder={placeholder}
        value={selectedKeywords}
        multiple={multiple}
        minLength={2}
        className={styles.keywordAutocompleteInput}
        onChange={onSelectKeyword}
        completeMethod={onSearchKeyword}
      />
    </div>
  )
}

export default KeywordAutocompleteSelection;