import { MutableRefObject, useEffect, useState } from "react";
import { MenuItem } from "primereact/menuitem";
import { useNavigate } from "react-router-dom";
import { DocumentHistoryPageRoute } from "../../pages/PagesRoutes";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { DocumentProps } from "@service/DocumentService/types";
import { Menubar } from "primereact/menubar";
import { Divider } from "primereact/divider";
import styles from "./PreviewDocument.module.css"
import { usePreviewDocumentContext } from "@others/context/PreviewDocumentContext";
import {classNames} from "primereact/utils";

interface NavbarToEditingDocumentsProps {
  isLoadingDownload: boolean;
  onDownloadDocument: () => void;
  refOverlayWithDocumentData: MutableRefObject<OverlayPanel | null>;
  mutatedDocument: DocumentProps | undefined;
}

interface MenuItemProps extends MenuItem {
  customEvent?: () => void
}

const NavbarToEditingDocuments = (
  {
    isLoadingDownload,
    onDownloadDocument,
    mutatedDocument
  }: NavbarToEditingDocumentsProps) => {
  const { setIsShowDialogToEditionOpen, isLoadingDocumentById, isLoadingDocumentToRender } = usePreviewDocumentContext();
  const [itemsToRenderMenuBar, setItemsToRenderMenuBar] = useState<MenuItem[]>([]);
  const navigate = useNavigate();
  const isLoading = isLoadingDocumentById || isLoadingDocumentToRender;

  const itemsToList: MenuItemProps[] = [
    { label: "Enviar E-mail", icon: "bi-envelope" },
    { label: "Download", icon: "bi-download", customEvent: () => onDownloadDocument() },
    { label: "Versões", icon: "bi-arrow-counterclockwise" },
    { label: "Comentários", icon: "bi-chat-fill" },
    { label: "Excluir", icon: "bi-trash" },
    { label: "Histórico", icon: "bi-card-list", customEvent: () => navigate(`${DocumentHistoryPageRoute}/${mutatedDocument?.id}`) },
  ]

  const mountMenuItemList = () => {
    let newList: MenuItem[] = [];
    newList.push(addDetailsButton());
    itemsToList.forEach(({ label, icon, visible, customEvent }: MenuItemProps) => {
      const newItem = {
        label: label,
        visible,
        template:
          <Button
            className={"p-menuitem-link lg:mx-3 lg:w-fit w-full my-1 lg:p-2 p-button-outlined text-left border-none " + classNames({"hidden": isLoadingDownload || isLoading})}
            icon={icon}
            onClick={() => customEvent && customEvent()}
            type="button"
            tooltip={label}
            tooltipOptions={{ mouseTrack: true, position: "left" }}
          >
            <span className="lg:hidden ml-2">{label}</span>
          </Button>
      }
      newList.push(newItem);
    })
    newList.push(addDivider())
    newList.push(addReturnButton())
    setItemsToRenderMenuBar(newList);
  }

  const addDetailsButton = () => {
    return {
      label: "Ver detalhes",
      template:
        <Button
          onClick={() => setIsShowDialogToEditionOpen(true)}
          className="p-button-sm lg:mx-3 lg:w-fit w-full my-1 lg:p-2 lg:bg-primary bg-transparent lg:text-white text-primary lg:border-round border-none"
          icon="bi-eye"
          type="button"
          tooltip="Ver detalhes"
          loading={isLoading}
          tooltipOptions={{ mouseTrack: true, position: "left" }}
        >
          <span className="lg:hidden ml-2">Ver detalhes</span>
        </Button>
    }
  }

  const addReturnButton = () => {
    return {
      className: "mx-1",
      label: "Voltar",
      template:
        <Button
          onClick={() => navigate(-1)}
          className={"p-button-sm lg:w-fit w-full text-left border-none p-button-warning p-button-outlined font-bold " +  classNames({"hidden": isLoadingDownload || isLoading})}
          icon="bi-arrow-return-left"
          type="button"
          tooltip="Voltar"
          tooltipOptions={{ mouseTrack: true, position: "left" }}
        >
          <span className="lg:hidden ml-2">Voltar</span>
        </Button>
    }
  }

  const addDivider = () => {
    return { template: <Divider/> }
  }

  useEffect(() => {
    mountMenuItemList();
  }, [mutatedDocument, isLoadingDocumentById, isLoadingDocumentToRender])

  return (
    <div className={styles.navBarContainer}>
      <Menubar
        model={itemsToRenderMenuBar}
        className={styles.menubar + " grid p-0 pt-2"}
      />
    </div>
  )
}

export default NavbarToEditingDocuments;